import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const RadioItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0.5em;

  input {
    cursor: pointer;
    width: 2em;
    height: 2em;
  }

  input:disabled {
    background: blue;
  }
`;

const RadioWrapper = styled.div`
  min-width: 400px;
  margin: 1em;
`;

function RadioButtons(props) {
  return (
    <RadioWrapper>
      <h3>{props.title}</h3>

      {props.displayConf.map((item, index) => {
        return (
          <RadioItem key={index}>
            <label data-tip={item.hint}>{item.name} </label>
            <input
              type='radio'
              disabled={props.readOnly ? false : true}
              value={item.name}
              checked={item.value}
              onClick={() => props.handleChange(index)}
              onChange={(e) => {}}
            />
          </RadioItem>
        );
      })}
      <ReactTooltip place='left' type='info' effect='solid' />
    </RadioWrapper>
  );
}

export default RadioButtons;
