import React, { useState } from 'react';
import RadioButtons from './RadioButtons';
import ToggleBoxes from './ToggleBoxes';
import styled from 'styled-components';
import { COLORS } from '../styles/constants';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const FlexWrapper = styled.div`
  display: flex;
  padding: 0.3em;
  justify-content: space-between;
`;

const ActionCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  background-color: transparent;
  color: ${COLORS.black};
  margin: 0 auto;
`;

const ActionCodeInput = styled.div`
  width: 30%;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    width: 100%;
    font-size: 2em;
    background-color: transparent;
    color: ${COLORS.black};
    border: 1px solid black;
    border-radius: 20px;
    padding: 0.3em;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }
`;

let outputConf = [
  {
    name: 'Operation Message',
    pcbName: 'POT_FREE_1',
    value: false,
  },
  {
    name: 'Alarm',
    pcbName: 'POT_FREE_2',
    value: false,
  },
  {
    name: 'Error',
    pcbName: 'POT_FREE_3',
    value: false,
  },
  {
    name: 'Filter Police',
    pcbName: 'POT_FREE_4',
    value: false,
  },
  {
    name: 'Filter 80%',
    pcbName: 'POT_FREE_5',
    value: false,
  },
  {
    name: 'Filter 100%',
    pcbName: 'POT_FREE_6',
    value: false,
  },
];

let actionConf = [
  {
    name: 'no action',
    hint: 'nothing happens',
    value: true,
  },
  {
    name: 'alarm',
    hint: 'display is orange, alarm symbol shown',
    value: false,
  },
  { name: 'error', hint: 'display is red, error symbol shown', value: false },
  {
    name: 'error with shutdown',
    hint: 'display is red, error symbol shown, unit stopped',
    value: false,
  },
];

const actionsTypes = {
  ACTION_NO_ACTION: 0,
  ACTION_ALARM: 1,
  ACTION_ERROR: 2,
  ACTION_ERROR_SHUT_DOWN: 3,
};

function bitSet(num, bit) {
  return num | (1 << bit);
}

function bitClean(num, bit) {
  return num & ~(1 << bit);
}

function zeroFill(number, width) {
  width -= number.toString().length;
  if (width > 0) {
    return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
  }
  return number + ''; // always return a string
}

function returnBit(number, bitPosition) {
  return (number & (0x0001 << bitPosition)) >> bitPosition;
}

function calculateCode(inputArray, actions) {
  let actionCode;

  let action = 0;
  if (actions[actionsTypes.ACTION_ALARM].value === true) {
    action = 2;
  } else if (actions[actionsTypes.ACTION_ERROR].value === true) {
    action = 3;
  } else if (actions[actionsTypes.ACTION_ERROR_SHUT_DOWN].value === true) {
    action = 5;
  } else if (actions[actionsTypes.ACTION_NO_ACTION].value === true) {
    action = 1;
  }

  let color = 1;
  let rgbAction = 7;

  let output = 0;
  for (let i = 0; i < inputArray.length; i++) {
    if (inputArray[i].value === false) {
      output = bitClean(output, i);
    } else {
      output = bitSet(output, i);
    }
  }
  console.log('output code' + output);

  output = zeroFill(output, 2);

  actionCode = `${color}${rgbAction}${action}${output}`;

  return actionCode;
}

function Actions() {
  const [outputStates, setOutputStates] = useState(outputConf);
  const [action, setAction] = useState(actionConf);
  const [userEnteredValue, setUserEnteredValue] = useState(17101);

  function decodeCode(code) {
    const codeAsString = code.toString();

    const color = codeAsString.charAt(0);
    const rgbType = codeAsString.charAt(1);
    const action = parseInt(codeAsString.charAt(2));
    const outputCode = parseInt(codeAsString.slice(-2));

    if (action <= 5) {
      actionConf.forEach((code) => (code.value = false));

      switch (action) {
        case 1:
          actionConf[actionsTypes.ACTION_NO_ACTION].value = true;
          break;
        case 2:
          actionConf[actionsTypes.ACTION_ALARM].value = true;
          break;
        case 3:
          actionConf[actionsTypes.ACTION_ERROR].value = true;
          break;
        case 5:
          actionConf[actionsTypes.ACTION_ERROR_SHUT_DOWN].value = true;
          break;
        default:
          break;
      }
    }

    for (let i = 0; i < 6; i++) {
      let bit = returnBit(outputCode, i);
      outputConf[i].value = bit;
    }

    setOutputStates(outputConf);
    setAction(actionConf);
    console.log(actionConf);
    console.log({ color, rgbType, action, outputCode });
  }

  function handleChange(index) {
    const newState = [...outputStates];
    newState[index].value = !newState[index].value;
    setOutputStates(newState);
  }

  function handleActionChange(index) {
    const newState = [...actionConf];
    newState.forEach((state) => (state.value = false));
    newState[index].value = true;
    setAction(newState);
  }

  const handleUserCodeChange = (e) => {
    if (e.target.value.toString().length <= 5) {
      console.log(e.target.value);
      setUserEnteredValue(e.target.value);
      decodeCode(e.target.value);
    }
  };

  return (
    <div>
      <Tabs
        onSelect={(index) => {
          outputConf.forEach((out) => (out.value = false));
          actionConf.forEach((act) => (act.value = false));
          setOutputStates(outputConf);
          setAction(actionConf);
          
          if (index === 1) {
            decodeCode(userEnteredValue);
          } else if (index === 0) {
            calculateCode(outputStates, action);
          }
        }}
      >
        <TabList>
          <Tab>I NEED the code</Tab>
          <Tab>I HAVE the code</Tab>
        </TabList>

        <TabPanel>
          <FlexWrapper>
            <div>
              <ToggleBoxes
                outputConf={outputStates}
                readOnly={true}
                handleChange={handleChange}
                title='Potential Free Outputs'
              ></ToggleBoxes>
              <RadioButtons
                title='Actions'
                readOnly={true}
                displayConf={actionConf}
                handleChange={handleActionChange}
              ></RadioButtons>
            </div>
            <ActionCode>{calculateCode(outputStates, action)}</ActionCode>
          </FlexWrapper>
        </TabPanel>

        <TabPanel>
          <FlexWrapper>
            <ActionCodeInput>
              <input
                type='number'
                value={userEnteredValue}
                onChange={handleUserCodeChange}
              />
            </ActionCodeInput>
            <div>
              <ToggleBoxes
                outputConf={outputStates}
                readOnly={false}
                handleChange={handleChange}
                title='Potential Free Outputs'
              ></ToggleBoxes>
              <RadioButtons
                title='Actions'
                readOnly={false}
                displayConf={actionConf}
                handleChange={handleActionChange}
              ></RadioButtons>
            </div>
          </FlexWrapper>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default Actions;
