import Papa from "papaparse";

function mergeAndPrint(standardPar, userPar) {
  let reWrittenParameterAmount = 0;
  userPar.forEach((par) => {
    const parIndex = standardPar.findIndex(
      (parNormal) => parNormal.code === par.code
    );
    if (parIndex !== -1) {
      console.log(
        `user par ${par.code} should rewritte parameter on index ${parIndex}`
      );
      standardPar[parIndex].value = par.value;
      standardPar[parIndex].rewritten = true;
      reWrittenParameterAmount += 1;
    } else {
      console.log(
        `user par ${par.code} was not present in the standard set, add it`
      );
      standardPar.push({
        code: par.code,
        value: par.value,
        rewritten: false,
      });
    }
   
  });
  console.log(`number of rewritten parameters: ${reWrittenParameterAmount}`);
  console.log(standardPar);

  let fileContent = [];
  for (let i = 0; i < standardPar.length; i++) {
    fileContent.push(standardPar[i].code);
    fileContent.push(standardPar[i].value);
  }
 

  var FileSaver = require("file-saver");
  var blob = new Blob(fileContent, {
    type: "text/plain;charset=utf-8",
  });
  FileSaver.saveAs(blob, "PAR.txt");
}

function formatFile(fileContent, parStorage) {
  var flattened = [].concat.apply([], fileContent);

  for (let i = 0; i < flattened.length; i++) {
    flattened[i] = `${flattened[i]} \r\n`;
  }
  // console.log(`flattened array`);
  // console.log(flattened);

  flattened = removeCommentsAndNewLines(flattened);

  // console.log(`no comments and new lines`);
  // console.log(flattened);

  for (let i = 0; i < flattened.length - 1; i = i + 2) {
    let par = {
      code: flattened[i],
      value: flattened[i + 1],
      rewritten: false,
    };
    parStorage.push(par);
  }
}

function loopFiles(files) {
  var standardParameterFile = [];
  var userSpecificParameterFile = [];
  let parStandard = [];
  let parUserSpecific = [];

  Promise.all(
    files.map((file) => {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          complete: function (results) {
            var data = results.data;
            if (file.path.includes("KUNDESPEC")) {
              userSpecificParameterFile.push(data);
            } else {
              standardParameterFile.push(data);
            }
            resolve(results);
          },
        });
      });
    })
  )
    .then(() => {
      formatFile(standardParameterFile, parStandard);
      formatFile(userSpecificParameterFile, parUserSpecific);
      mergeAndPrint(parStandard, parUserSpecific);
    })
    .catch((err) => console.warn("Something went wrong:", err));
}

function removeCommentsAndNewLines(array) {
  const noCommentsArray = array.filter(
    (line) => line.trim().startsWith("*") === false
  );

  const noNewLinesArray = [];
  noCommentsArray.forEach((el, i) => {
    let aux = el.replace(/\r\n/g, "");
    // console.log(`each ${aux}`);

    if (aux.replace(/\s/g, "") === "") {
      // console.log("found empty");
    } else {
      if (!aux.startsWith("P")) {
        aux = `${aux}\r\n`;
      }
      noNewLinesArray.push(aux);
    }
  });

  return noNewLinesArray;
}

export function parseFiles(files) {
  console.log(files);
  loopFiles(files);
}
