import React, { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsDownload } from 'react-icons/bs';
import { RiDeleteBin5Line } from 'react-icons/ri';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { COLORS } from '../styles/constants';
import FileList from './FileList';
import { parseFiles } from './FileParser';
import Notifier from './Notifier';


const baseStyle = {
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 8em;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 15%;
  min-width: 80px;

  button {
    width: 100%;
    height: 30%;
    margin: 0.5em;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 2em;
    color: ${COLORS.black};
  }

  button:disabled,
  button[disabled] {
    cursor: not-allowed;
    color: #666666;
  }

  .delete-button:hover {
    color: ${COLORS.redAccent};
  }
  .download-button:hover {
    color: green;
  }

  button:disabled:hover,
  button[disabled]:hover {
    color: #666666;
  }
`;

function LoadFiles(props) {
  const [files, setFiles] = React.useState([]);
  const [noticeWrongFormat, setNoticeWrongFormat] = React.useState(false);
  const [noticeFileExists, setNoticeFileExists] = React.useState(false);

  useEffect(() => {
    setNoticeWrongFormat(false);
    setNoticeFileExists(false);
  }, [noticeWrongFormat, noticeFileExists]);

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      const acceptedAndProofedFiles = [];
      let fileAlreadyExists = false;
      acceptedFiles.forEach((file) => {
        if (file.type !== 'text/plain') {
          console.log('Wrong file format');
          setNoticeWrongFormat(true);
          return;
        } else {
          console.log('File format OK');
        }

        files.forEach((el) => {
          console.log(el);
          if (el.path === file.path) {
            console.log('file already exists');
            setNoticeFileExists(true);
            fileAlreadyExists = true;
          }
        });

        if (fileAlreadyExists === false) {
          acceptedAndProofedFiles.push(file);
        }
      });

      setFiles((prev) => [...prev, ...acceptedAndProofedFiles]);
    },
    [files]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  function deleteFiles() {
    setFiles([]);
  }

  return (
    <>
      <ControlContainer>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>

        <ButtonContainer>
          <button
            onClick={() => {
              deleteFiles();
              ReactTooltip.hide();
            }}
            disabled={files.length === 0}
            data-tip='Delete all'
            className='delete-button'
          >
            <RiDeleteBin5Line />
          </button>

          <button
            onClick={() => {
              parseFiles(files);
              ReactTooltip.hide();
            }}
            disabled={files.length === 0}
            data-tip='Download SD card file'
            data-type='success'
            className='download-button'
          >
            <BsDownload />
          </button>
        </ButtonContainer>
      </ControlContainer>
      <aside>
        <FileList files={files} setFiles={setFiles} />

        <Notifier
          noticeWrongFormat={noticeWrongFormat}
          noticeFileExists={noticeFileExists}
        />
      </aside>
      <ReactTooltip place='right' type='error' effect='solid' />
    </>
  );
}

export default LoadFiles;
