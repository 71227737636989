

export const COLORS = {
  text: 'black',
  background: 'white',
  primary: 'rebeccapurple',
  black: '#0F1A20',
  lightBlue: '#88A2AA',
  redAccent: '#F42C04',
  green: '#3CB371'
};

