import React, { useEffect } from 'react';
import { TiDeleteOutline } from 'react-icons/ti';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { COLORS } from '../styles/constants';

const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  background-color: transparent;
  color: ${COLORS.black};
  cursor: pointer;
  width: 15%;
  border: none;

  &:hover {
    color: ${COLORS.redAccent};
    transition: all linear 0.1s;
  }
`;

const FileListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 3em;
  
    
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 5em;
    width: 100%;
    margin-top: 0.5em;
    background-color: #fafafa;
    border-radius: 2px;
    overflow: hidden;
    span {
      width: 80%;
    }
  }

  span {
    padding: 0.1em 0.5em;
  }
`;

export default function FileList({ files, setFiles }) {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const deleteHandler = (path) => {
    console.log(path);
    setFiles(files.filter((el) => el.path !== path));
  };

  const fileList = files.map((file) => {

    let pathString = file.path;
    console.log(file.path);

    
    if(pathString.length > 50) {
      pathString = pathString.substring(0,50);
      pathString = pathString+'...';
    } 

    return ( 
    <li key={file.path}>
      <span> {pathString} </span>
      <DeleteButton
        onClick={() => {
          deleteHandler(file.path);
          ReactTooltip.hide();
        }}
        data-tip={`delete ${file.path}`}
      >
        <TiDeleteOutline />
      </DeleteButton>
    </li>
    )
  });

  return (
    <>
      <FileListWrapper>
        <ul>{fileList}</ul>
      </FileListWrapper>

      <ReactTooltip place='right' type='error' effect='solid' />
    </>
  );
}
