import React from "react";
import { AiFillBug } from "react-icons/ai";
import { Link, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import "./App.css";
import Actions from "./components/Actions";
import Dosing from "./components/Dosing";
import Footer from "./components/Footer";
import Home from "./components/Home";
import LoadFiles from "./components/LoadFiles";
import MainNav from "./components/MainNav";

const Content = styled.div`
  flex-grow: 1;
  min-height: 20em;
  margin: 0 auto;
  width: 100%;
  margin-top: 0.8em;
  height: auto;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4.5em;
  margin-left: 6em;
  width: 60%;
`;

const SideBar = styled.div`
  width: 15px;
  background-color: black;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-top: 0px;
  .navbar-logo {
    color: black;
    font-size: 10em;
    margin-top: 50px;
  }
`;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  height: auto;
  width: 70%;
  min-width: 600px;
  flex-direction: row;
  justify-content: center;
  background-color: #e4e1dd;
  margin: 0 auto;
`;

function App() {
  if (process.env.REACT_APP_REMOVE_CONSOLE_LOG === "true") {
    console.log = function () {};
  }

  return (
    <Wrapper className="wrapper">
      <SideBar className="sidebar">
        <Link to="/">
          <AiFillBug className="navbar-logo" />{" "}
        </Link>{" "}
      </SideBar>

      <ContentContainer className="content-container">
        <MainNav />
        <Content className="content">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/files" component={LoadFiles} />
            <Route path="/actions" component={Actions} />
            <Route path="/dosing" component={Dosing} />
          </Switch>
        </Content>
        <Footer />
      </ContentContainer>
    </Wrapper>
  );
}

export default App;
