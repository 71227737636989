import React from 'react'

function Dosing() {
    return (
        <div>
            to do dosing calc
        </div>
    )
}

export default Dosing
