import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Notifier(props) {
// console.log(props);

  const notifyConf = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  };

  const notifyWrongFormat = () => toast('Wrong file format. Text files only!', notifyConf);
  const notifyFileExists = () => toast('File already imported!', notifyConf);

  
  if (props?.noticeWrongFormat === true) {
    notifyWrongFormat();
  }

  if (props?.noticeFileExists === true) {
    notifyFileExists();
  }

  return (
    <div>
      <ToastContainer
        position='top-center'
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Notifier;
