import React from 'react';
import Switch from 'react-switch';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const Output = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0.5em;
`;

const OutputWrapper = styled.div`
  min-width: 400px;
  margin: 1em;
`;

function ToggleBoxes(props) {
  return (
    <OutputWrapper>
      <h3>{props.title}</h3>
      {props.outputConf.map((output, index) => {
        return (
          <Output key={index}>
            <span data-tip={output.pcbName}>{output.name}</span>
            <Switch
              onChange={() => props.handleChange(index)}
              checked={output.value ? true : false}
              width={100}
              disabled={props.readOnly ? false : true}
              uncheckedIcon={
                <div
                  style={{
                    marginLeft: '-1.1em',
                    paddingTop: '0.3em',
                  }}
                >
                  opened
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    marginLeft: '1em',
                    paddingTop: '0.3em',
                  }}
                >
                  closed
                </div>
              }
            />
          </Output>
        );
      })}

      <ReactTooltip place='left' type='info' effect='solid' />
    </OutputWrapper>
  );
}

export default ToggleBoxes;
