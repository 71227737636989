import React from "react";
import styled from "styled-components";

const FootDiv = styled.div`
  padding-top: 3rem;
  padding-bottom: 1rem;

  margin: 0 auto;

  width: 100%;
  .highlight {
    font-weight: bolder;
  }
`;

export default function Footer() {
  const packageJson = require("../../package.json");
  return (
    <>
      <FootDiv className="footer">
        you are on <span className="highlight">is-not-a-bug</span> by EV 2023 V
        {packageJson.version}.
      </FootDiv>
    </>
  );
}
