import React from "react";
import { FaProjectDiagram, FaUsb } from "react-icons/fa";
import { GoFileBinary } from "react-icons/go";
import { ImPower } from "react-icons/im";
import styled from "styled-components";
import { COLORS } from "../styles/constants";

const HomeUl = styled.ul`
  margin-top: 2em;
  list-style-type: none;
  a {
    color: ${COLORS.black};
    text-decoration: none;
    text-transform: lowercase;
    font-weight: 700;
    opacity: 80%;
  }

  a:hover > .icon {
    color: ${COLORS.primary};
  }

  li {
    padding: 0.5em;
  }

  .icon {
    color: ${COLORS.text};
    font-size: 1.3em;
    position: relative;
    top: 6px;
    margin-right: 0.5em;
  }
`;

export default function Home() {
  return (
    <>
      <h2>Hi. Some usefull links</h2>
      <HomeUl>
        <li>
          <a
            href="https://app.diagrams.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaProjectDiagram className="icon" />
            Online client-side (nothing is saved on the server) XML editor{" "}
          </a>
        </li>
        <li>
          <a
            href="https://www.dcode.fr/binary-image"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GoFileBinary className="icon" />
            Converting images to 0/1 for SYMBOLS{" "}
          </a>
        </li>
        <li>
          <a
            href="https://www.rapidtables.com/calc/electric/power-calculator.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImPower className="icon" />
            AC/DC power calculator{" "}
          </a>
        </li>
        <li>
          <a
            href="https://www.mathsisfun.com/binary-decimal-hexadecimal-converter.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GoFileBinary className="icon" />
            DEC-BIN-HEX converter{" "}
          </a>
        </li>
        <li>
          <a
            href="http://en.radzio.dxp.pl/modbus-master-simulator/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaUsb className="icon" />
            Free Modbus Master simulator (no install){" "}
          </a>
        </li>
      </HomeUl>
    </>
  );
}
