import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../styles/constants";

const NavUl = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
`;

const NavLi = styled.li`
  display: inline;
  color: red;
  padding-bottom: 2rem;

  a {
    font-size: 1rem;
    color: ${COLORS.black};
    text-decoration: none;
    text-transform: lowercase;
    font-weight: 700;
    transition: 0.1s linear all;
    opacity: 80%;
  }
  a:hover {
    opacity: 100%;
  }
`;

const NavContainer = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 3em;
  min-width: 600px;

  .navbar-logo {
    color: ${COLORS.black};
    font-size: 5em;
  }
`;

const MainNav = () => (
  <NavContainer className="nav-container">
    <NavUl>
      <NavLi>
        <Link to="/">Home </Link>
      </NavLi>
      <NavLi>
        <Link to="/files">SD Card Files </Link>
      </NavLi>
      <NavLi>
        <Link to="/actions">Actions </Link>
      </NavLi>
    </NavUl>
  </NavContainer>
);

export default MainNav;
